import React from 'react'
import { graphql, Link } from 'gatsby'

import WorkContainer from '../../components/WorkContainer'
import Layout from '../../components/Layout'
import { ProjectTitle, EnglishOnly } from '../../components/Text'
import Metadata from '../../components/Metadata'
import CustomerLogo from '../../components/CustomerLogo'
import ProjectImage from '../../components/ProjectImage'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function Taloc({ data }) {
  const { t } = useTranslation()

  const project = data.projectsJson

  return (
    <Layout>
      <Metadata title={t(project.title)} isArticle />
      <WorkContainer>
        <CustomerLogo project={project} />
        <ProjectTitle title={t(project.title)} />
        <EnglishOnly />
        <ProjectImage data={project.gallery.main} top />
        <h2>Summary</h2>
        <p>
          A web-based software application helps universities easily identify
          best opportunities to reduce emissions, together with well-defined
          populations to target, thanks to a unique approach to data
          visualization and data-preserving anonymization.
        </p>
        <p>Find the demo link further down.</p>
        <h2>Need</h2>
        <p>
          The goal at Zurich University of Applied Sciences (ZHAW) is to{' '}
          <em>measurably</em> reduce their own emissions through an
          evidence-based, motivational governance.
        </p>
        <blockquote>Measurable reductions, motivational governance</blockquote>
        <p>
          <ul>
            <li>
              For <em>measurable reductions</em>, we need to be able to easily
              understand which emission-generating behavior is worth amending
              for what populations.
            </li>
            <li>
              For <em>motivational governance</em>, we have to get staff and
              students on board. They need insight into what interventions are
              created for which reason, and become empowered to contribute to
              the decision-making.
            </li>
          </ul>
        </p>
        <h2>Approach</h2>
        <p>
          We introduce a public, <em>web-based platform</em> to visualize all
          emission data with helpful additional context. The platform is
          accompanied with a <em>data loader</em> that cleans, merges, and
          anonymizes all emission data before they enter the platform.
        </p>
        <p>
          The platform solves 2 key needs:
          <ol>
            <li>
              It's easy to explore and find emission-related behavior worth
              amending.
            </li>
            <li>
              The behavior to amend is linked to well-defined populations.
              Therefore, a policy or intervention can be targeted at a precise
              audience.
            </li>
          </ol>
        </p>
        <blockquote>Powerful data science made easy to use</blockquote>
        <p>
          We do this by providing unique data visualizations that are deeply
          informative, but are easy to use at the same time. Decision-makers
          without a data science background can now answer questions like:
          <ul>
            <li>
              „How many tons CO2 would we save if everyone switched from plane
              to train for certain distances or destinations?“
            </li>
            <li>„Which populations are flying overseas, for what reasons?“</li>
            <li>
              „How does travel depend on age, department, travel reason, and
              gender?“
            </li>
          </ul>
        </p>
        <p>
          The companion data loader application merges data from various sources
          and solves issues such as staff names spelled differently across
          various databases. Most importantly, it anonymizes all data with an
          algorithm that protects the privacy of all individuals without giving
          up important details like age, gender, and more. Check out a more
          detailed description about this anonymization at the{' '}
          <Link to="../mondrian">Mondrian page</Link>.
        </p>
        <h2>Result</h2>
        <p>
          The first version of the product is now live. It has already delivered
          important, previously unknown insights into the ZHAW emission behavior
          to decision-makers.{' '}
          <a
            href="http://taloc-demo.gigmade.com"
            target="_blank"
            rel="noreferrer"
          >
            Click here to play the flights demo!
          </a>
        </p>
        <ProjectImage data={project.gallery.sunburst} />
        <h2>Outlook</h2>
        <blockquote>“Evidence-based Sustainability Governance”</blockquote>
        <p>
          This product is part of a larger project called{' '}
          <em>Evidence-based Sustainability Governance</em>.
        </p>
        <p>
          As a next big feature, we plan to support "intervention tracking".
          This feature will allow decision makers to introduce{' '}
          <em>experimental interventions</em> for reducing emissions, and test
          them before making them permanent policy.
        </p>
        <p>
          Finally, we will combine this with existing gigmade technology for
          dynamic decision making, to enable a decentral forum where
          experimental interventions are <em>negotiated and prioritized</em>.
        </p>
      </WorkContainer>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    projectsJson(name: { eq: "taloc" }) {
      name
      title
      customer
      customerLogo {
        childImageSharp {
          gatsbyImageData
        }
      }
      tags
      link
      gallery {
        main {
          alt
          src {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        sunburst {
          alt
          src {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
